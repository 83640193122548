import React from "react"
import { Button } from "../Button/Button"
import { Link } from "../Link/Link"

export type NotFoundProps = {}

export const NotFound: React.FC<NotFoundProps> = props => {
  return (
    <section className="relative py-24 md:py-44 lg:h-screen lg:py-0 lg:flex bg-white">
      <div className="relative z-10 container px-4 mx-auto lg:m-auto">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="mb-4 mt-2 text-4xl font-display md:text-5xl leading-tight font-bold tracking-tighter">
            Page not found
          </h2>
          <p className="mb-12 text-lg md:text-xl text-slate-500">
            I wonder why you came here ? We are sorry if the website is broken.
          </p>
          <div className="flex flex-wrap justify-center">
            <Link url="/">
              <Button>Go to the home page</Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
